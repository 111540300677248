<template>
  <div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="thead">
        <vs-th>
          <vs-checkbox color="success" v-model="selectAll">
            Check All
          </vs-checkbox>
        </vs-th>
      </template>
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.invoices">
          <vs-td>
            <vs-checkbox
              color="success"
              :checked="isChecked(tr.ID)"
              @click="checkboxHandle(tr.ID)"
            >
            </vs-checkbox>
            <!-- <invoice-checkbox
              :checked="isChecked(tr.ID)"
              @click="checkboxHandle(tr.ID)"
              :invoiceValue="tr.Total"
              :invoiceId="tr.ID"
            /> -->
          </vs-td>
          <vs-td>
            {{ tr.Code }}
          </vs-td>
          <vs-td>
            Code : {{ tr.ClientCode }}<br />
            Name : {{ tr.ClientName }}<br />
          </vs-td>
          <vs-td>
            {{ dateFormat(tr.CreatedAt) }}
          </vs-td>
          <vs-td>
            {{ priceFormat(tr.Total) }}
          </vs-td>
          <vs-td>
            <value-input
              :invoiceId="tr.ID"
              :invoiceValue="tr.Total"
              :isChecked="isChecked(tr.ID)"
            />
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import moment from "moment";
import valueInput from "./value-input.vue";
import invoiceCheckbox from "./invoice-checkbox.vue";
import { invoiceARClearing } from "../../../../../services/api/invoice";

export default {
  created() {
    this.$store.watch(
      (state) => state.arClearing.selectedCustomer,
      (newVal) => {
        this.params.customer_code = newVal.code;
      }
    );
  },
  components: {
    "value-input": valueInput,
    "invoice-checkbox": invoiceCheckbox,
  },
  watch: {
    selectAll() {
      if (this.selectAll) {
        console.log("all checked");
        // invoices = [...this.responseData.invoices];
        this.$store.commit("arClearing/setInvoice", [
          ...this.responseData.invoices,
        ]);
      } else {
        console.log("all unchecked");
        // invoices = [];
        this.$store.commit("arClearing/setInvoice", []);
      }
    },
  },
  data() {
    const selectedCustomer = this.$store.state.arClearing.selectedCustomer;
    return {
      isViewRendered: false,
      selectAll: false,
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
        customer_code: selectedCustomer === null ? "" : selectedCustomer.code,
        stats: [1, 3],
      },
      header: [
        {
          text: "Code",
          value: "code",
        },
        {
          text: "Customer",
          value: "customer_name",
          // width: '5%'
        },
        {
          text: "Date",
          sortable: false,
          // width: '5%'
        },
        {
          text: "Remaining",
        },
        {
          text: "Paid Value",
        },
      ],
      responseData: {},
    };
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("MM/DD/YYYY hh:mm");
      }
    },
    reloadData(params) {
      const newParams = { ...this.params };
      newParams.page = params.page;
      newParams.search = params.search;
      this.$vs.loading();

      const invoices = invoiceARClearing(newParams);
      invoices.then((r) => {
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          console.log("resp", this.responseData);
          this.$vs.loading.close();
          this.responseData = r.data;
          this.responseData.length = r.data.length;
          this.responseData.recordsTotal = r.data.recordsTotal;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    checkboxHandle(id) {
      const cn = this.responseData.invoices.find((cn) => cn.ID === id);
      const invoices = JSON.parse(
        JSON.stringify(this.$store.state.arClearing.selectedInvoice)
      );
      if (invoices.length === 0) {
        invoices.push(cn);
        this.$store.commit("arClearing/setInvoice", invoices);
        return;
      }

      if (invoices.some((cn) => cn.ID === id)) {
        const index = invoices.findIndex((cn) => cn.ID === id);
        invoices.splice(index, 1);
      } else {
        invoices.push(cn);
      }
      this.$store.commit("arClearing/setInvoice", invoices);
    },
    isChecked(id) {
      //   console.log(invoices);
      const invoices = this.$store.state.arClearing.selectedInvoice;
      if (invoices === 0) {
        return false;
      }
      return invoices.some((cn) => cn.ID === id);
    },
    // checkAll() {
    //   this.$nextTick(() => {
    //     console.log("select All func", this.selectAll);
    //     if (this.selectAll) {
    //       console.log("all checked");
    //       invoices = [...this.responseData.invoices];
    //     } else {
    //       console.log("all unchecked");
    //       invoices = [];
    //     }
    //   });
    // },
  },
};
</script>
