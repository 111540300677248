<template>
  <div
    class="flex flex-col flex-grow gap-3 min-w-1/5 w-1/2 border-solid border-0 border-l border-blue-900 border-grey-light ml-4 pl-4 overflow-auto"
    style="max-height: 300px; height: 200px"
  >
    <h3>{{ dataType === "cn" ? "Credit Note" : "Customer Deposit" }}</h3>
    <vs-table :data="selected" class="w-full" style="width: 100%">
      <template slot="thead">
        <vs-th> Code </vs-th>
        <vs-th> Customer </vs-th>
        <vs-th> Date </vs-th>
        <vs-th> Remaining </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].Code">
            {{ data[indextr].Code }}
          </vs-td>
          <vs-td v-if="dataType === `cn`">
            Code : {{ data[indextr].CustomerCode }}<br />
            Name : {{ data[indextr].CustomerName }}<br />
          </vs-td>

          <vs-td v-else>
            Code : {{ data[indextr].PersonCode }}<br />
            Name : {{ data[indextr].PersonName }}<br />
          </vs-td>

          <vs-td>
            {{ dateFormat(data[indextr].CreatedAt) }}
          </vs-td>

          <vs-td :data="data[indextr].Code" v-if="dataType === `cn`">
            {{
              priceFormat(data[indextr].TotalValue - data[indextr].UsedValue)
            }}
          </vs-td>

          <vs-td :data="data[indextr].Code" v-else>
            {{ priceFormat(data[indextr].DepositValue) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      selected: [],
      dataType: String,
    };
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("MM/DD/YYYY hh:mm");
      }
    },
  },
  mounted() {
    this.dataType = this.$store.state.arClearing.radioFilterValue;
    const temp =
      this.$store.state.arClearing.radioFilterValue === `cn`
        ? this.$store.state.arClearing.selectedCreditNote
        : this.$store.state.arClearing.selectedCustomerDeposit;
    this.selected = JSON.parse(JSON.stringify(temp));

    console.log(
      "DATA : ",
      this.selected,
      this.$store.state.arClearing.radioFilterValue === `cn`
    );
  },
};
</script>
