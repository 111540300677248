<template>
  <div class="flex flex-col w-full gap-3">
    <div class="flex flex-row">
      <date-pickers />
      <selected-cn-dn />
    </div>
    <div class="flex flex-row justify-start w-full gap-3">
      <vs-button
        type="border"
        class="mt-1 mb-4"
        style="height: 40px"
        @click="onNavigate"
        >Back</vs-button
      >
      <vs-button class="mt-1 mb-4" style="height: 40px" @click="postARClearing"
        >Clearing</vs-button
      >
    </div>
    <invoice-data-view />
  </div>
</template>

<script>
import invoiceDataview from "./invoice-dataview.vue";
import datePickers from "./date-pickers.vue";
import selectedCnDn from "./selected-cn-dn.vue";

export default {
  components: {
    "invoice-data-view": invoiceDataview,
    "date-pickers": datePickers,
    "selected-cn-dn": selectedCnDn,
  },
  methods: {
    onNavigate() {
      this.$store.commit("arClearing/setTabIndex", 0);
    },
    ArClearing(body) {
      return this.$http.post("/api/v1/payment/ar-clearing", body);
    },
    postARClearing() {
      const selectedInvoices = this.$store.state.arClearing.selectedInvoice;
      if (selectedInvoices.length === 0) {
        this.$vs.notify({
          title: "AR Clearing",
          text: "Invoice must be selected first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      const postingDate = this.$store.state.arClearing.postingDate.date;
      if (!postingDate) {
        this.$vs.notify({
          title: "AR Clearing",
          text: "Posting date be selected first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      const paymentDate = this.$store.state.arClearing.paymentDate.date;
      if (!paymentDate) {
        this.$vs.notify({
          title: "AR Clearing",
          text: "Payment date be selected first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      const radioValue = this.$store.state.arClearing.radioFilterValue;

      let body = {};
      if (radioValue === "cn") {
        body.credit_note_ids =
          this.$store.getters["arClearing/getSelectedCreditNotesIds"];
      } else {
        body.customer_deposit_ids =
          this.$store.getters["arClearing/getSelectedCustomerDepositsIds"];
      }

      const invoiceIds =
        this.$store.getters["arClearing/getSelectedInvoiceIds"];
      const selectedCustomer = this.$store.state.arClearing.selectedCustomer;
      const invoicePaidValues = JSON.parse(
        JSON.stringify(this.$store.state.arClearing.invoicePaidValue)
      );
      invoicePaidValues.sort((a, b) => b.ID - a.ID);
      body = {
        ...body,
        posting_date: postingDate,
        payment_date: paymentDate,
        invoice_paid_values: this.$store.state.arClearing.invoicePaidValue,
        customer_code: selectedCustomer.code,
        customer_id: selectedCustomer.ID,
        invoice_ids: invoiceIds,
      };

      this.$vs.loading();
      this.ArClearing(body)
        .then((resp) => {
          this.$vs.loading.close();
          console.log(resp);
          if (resp.code > 299) {
            this.$vs.notify({
              title: "AR Clearing",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            return;
          }
          this.$vs.notify({
            title: "AR Clearing",
            text: "AR Clearing success",
            color: "success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-checked",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
          this.$vs.notify({
            title: "AR Clearing",
            text: err.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        })
        .finally(() => {
          this.$store.commit("arClearing/setTabIndex", 0);
          this.$store.commit("arClearing/resetState");
        });
    },
  },
};
</script>
