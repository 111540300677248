<template>
  <div class="flex md:flex-col sm:flex-row min-w-1/5 w-1/2 gap-3">
    <div class="flex flex-col w-full gap-3">
      <label>Posting Date</label>
      <date-range-picker
        style="min-height: 40px"
        class="w-full"
        ref="picker"
        opens="right"
        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
        :singleDatePicker="true"
        :timePicker="false"
        :timePicker24Hour="false"
        :showWeekNumbers="false"
        :showDropdowns="false"
        :autoApply="true"
        :linkedCalendars="true"
        :maxDate="maxDate"
        v-model="postingdDate"
      >
        <template v-slot:input="picker">
          <div v-if="picker.startDate">
            {{ globalDateFormat(picker.startDate) }}
          </div>
          <div v-else>Please pick a date</div>
        </template>
      </date-range-picker>
    </div>

    <div class="flex flex-col w-full gap-3">
      <label>Payment Date</label>
      <date-range-picker
        style="min-height: 40px"
        class="w-full"
        ref="picker"
        opens="right"
        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
        :singleDatePicker="true"
        :timePicker="false"
        :timePicker24Hour="false"
        :showWeekNumbers="false"
        :showDropdowns="false"
        :autoApply="true"
        :linkedCalendars="true"
        :maxDate="maxDate"
        v-model="paymentDate"
      >
        <template v-slot:input="picker">
          <div v-if="picker.startDate">
            {{ globalDateFormat(picker.startDate) }}
          </div>
          <div v-else>Please pick a date</div>
        </template>
      </date-range-picker>
    </div>
  </div>
</template>

<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
export default {
  components: {
    "date-range-picker": DateRangePicker,
  },
  watch: {
    postingdDate() {
      const postDate = moment(this.postingdDate.startDate).format("YYYY-MM-DD");
      this.$store.commit("arClearing/setPostingDate", postDate);
    },
    paymentDate() {
      const postDate = moment(this.paymentDate.startDate).format("YYYY-MM-DD");
      this.$store.commit("arClearing/setPaymentDate", postDate);
    },
  },
  data() {
    return {
      maxDate: moment().toLocaleString(),
      postingdDate: {
        startDate: null,
        endDate: null,
      },
      paymentDate: {
        startDate: null,
        endDate: null,
      },
    };
  },
};
</script>
