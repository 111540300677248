<template>
  <vs-checkbox
    color="success"
    :checked="checked"
    @click="checkboxClicked"
    v-model="value"
  />
</template>

<script>
export default {
  props: {
    checked: Boolean,
    invoiceValue: Number,
    invoiceId: Number,
  },
  data() {
    return {
      value: false,
    };
  },
  methods: {
    checkboxClicked() {
      this.$emit("click");
    },
  },
  watch: {
    value() {
      // console.log("HAHAHAHAHAHAH");
      const invoicePaidValues = [
        ...this.$store.state.arClearing.invoicePaidValue,
      ];
      const index = invoicePaidValues.findIndex(
        (item) => item.id === this.invoiceId
      );
      if (this.value && index === -1) {
        invoicePaidValues.push({
          id: this.invoiceId,
          paid_value: parseFloat(this.value),
        });
      } else {
        invoicePaidValues.splice(index, 1);
      }
      this.$store.commit("arClearing/setinvoicePaidValue", invoicePaidValues);
      console.log("invoicePaidValues : ", invoicePaidValues);
    },
  },
};
</script>
