<template>
  <vs-input
    v-model="value"
    @input="onChange"
    @keyup="onKeyup"
    :disabled="!isChecked"
  />
</template>

<script>
export default {
  props: {
    invoiceValue: Number,
    invoiceId: Number,
    isChecked: Boolean,
  },
  data() {
    return {
      value: "",
      isInputDisabled: true,
    };
  },
  mounted() {
    if (this.isChecked) {
      this.value = this.formatPrice(this.invoiceValue.toFixed(2));
      this.isInputDisabled = false;
    } else {
      this.value = "";
      this.isInputDisabled = true;
    }
  },
  methods: {
    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onKeyup() {
      this.value = this.formatPrice(this.value.toString());
    },
    onChange(val) {
      //remove alphabet and coma

      let value = val.replace(/[^\d.]/g, "");
      const newValue = parseFloat(value !== "" ? value : 0);
      const invValue = parseFloat(this.invoiceValue);

      if (newValue > invValue) {
        this.value = invValue.toFixed(2);
        return;
      }

      const invoicePaidValues = JSON.parse(
        JSON.stringify(this.$store.state.arClearing.invoicePaidValue)
      );
      const index = invoicePaidValues.findIndex(
        (item) => item.id === this.invoiceId
      );

      if (index === -1) {
        console.log("index : ", index);
        return;
      }

      invoicePaidValues[index].paid_value = newValue;
      this.$store.commit("arClearing/setinvoicePaidValue", invoicePaidValues);
      console.log("invoicePaidValues : ", invoicePaidValues);
      this.value = val;
    },
  },
  watch: {
    isChecked() {
      console.log("here is checked");
      //   const invoicePaidValues = [
      //     ...this.$store.state.arClearing.invoicePaidValue,
      //   ];
      //   const index = invoicePaidValues.findIndex(
      //     (item) => item.id === this.invoiceId
      //   );

      if (this.isChecked) {
        this.isInputDisabled = false;
        this.value = "";
        // if (index !== -1) {
        //   return;
        // }
        // invoicePaidValues.push({
        //   id: this.invoiceId,
        //   paid_value: parseFloat(this.value),
        // });
      } else {
        this.isInputDisabled = true;
        // invoicePaidValues.splice(index, 1);
      }
      //   this.$store.commit("arClearing/setinvoicePaidValue", invoicePaidValues);
      //   console.log("invoicePaidValues : ", invoicePaidValues);
    },

    isInputDisabled() {
      const invoicePaidValues = [
        ...this.$store.state.arClearing.invoicePaidValue,
      ];
      const index = invoicePaidValues.findIndex(
        (item) => item.id === this.invoiceId
      );
      if (this.isInputDisabled) {
        this.value = "";
        invoicePaidValues.splice(index, 1);
      } else {
        this.value = this.formatPrice(this.invoiceValue.toFixed(2));
        this.isInputDisabled = false;
        if (index !== -1) {
          return;
        }
        const tempVal = parseFloat(this.value.replace(/,/g, ""));
        invoicePaidValues.push({
          id: this.invoiceId,
          paid_value: parseFloat(tempVal),
        });
      }
      this.$store.commit("arClearing/setinvoicePaidValue", invoicePaidValues);
      console.log("invoicePaidValues : ", invoicePaidValues);
    },
  },
};
</script>
